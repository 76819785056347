import React, { useEffect, useState } from "react";
import CreateUser from "./CreateUser";
import MembersTab from "./Components/MembersTab";
import TabsHeader from "components/TabsHeader";
import { useSearchParams } from "react-router-dom";
import { usePerms } from "hooks/usePerms";
import * as api from "../../apis/FusionAPI";
import { getVisibleUsers } from "utils/roleUtils";

export default function UserManager() {
  const [params, setParams] = useSearchParams();
  const perms = usePerms();

  const [tab, setTab] = useState([0]);
  const [roleList, setRoleList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  // console.log("Tab in role manager", tab);

  useEffect(() => {
    setTab(params.get("tab") ? [parseInt(params.get("tab"))] : [0]);

    (async () => {
      await api.getAllRoles().then((res) => {
        setRoleList([...res.data.result]);
      });

      await api
      .getWorkplaceMembers(getVisibleUsers(perms))

        .then((res) => {
          console.log("Get all members:", res);
          setMemberList([...res.data.result]);
        });
    })();
  }, []);

  const renderTab = () => {
    switch (tab[0]) {
      case 0: //members
        if (perms.testPermission([8, 10, 11, 12, 13], ["ViewUsers"]))
          return (
            <MembersTab
              list={memberList}
              setMemberList={setMemberList}
              mousePosition={mousePosition}
              perms={perms}
              setMousePosition={setMousePosition}
              roleList={roleList}
              onCreateUser={() => {
                setTab([1]);
              }}
            />
          );
      case 1: //create member
        if (perms.testPermission([8, 10, 11, 12, 13], ["Create-EditUsers"]))
          return <CreateUser />;
      default:
        return <></>;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        // border: "3px solid green",
        // boxSizing: "border-box",
        // background:'rgba(0,255,0,0.5)'
      }}
    >
      {/* header */}
      <div
        style={{
          width: "100%",
          // background:'rgba(255,0,0,0.5)'
        }}
      >
        <TabsHeader
          currentTabs={[tab]}
          onTabChange={(newTab) => setTab(newTab)}
          options={[{ option: "Users" }, { option: "Create New User" }]}
        />
      </div>

      {/* content */}
      <div
        style={{
          height: "calc(100% - 48px)",
          // background:'rgba(0,0,255,0.5)'
        }}
      >
        {renderTab()}
      </div>
    </div>
  );
}
