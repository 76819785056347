import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import * as api from "../../apis/FusionAPI";
import { usePerms } from "hooks/usePerms";
import TabsHeader from "components/TabsHeader";
import RolesTab from "./Components/RolesTab";
import MembersTab from "./Components/MembersTab";
import { getVisibleUsers } from "utils/roleUtils";


export default function RoleManager() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const perms = usePerms();

  const [tab, setTab] = useState([0]);
  const [roleList, setRoleList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  

  useEffect(() => {
    setTab(params.get("tab") ? [parseInt(params.get("tab"))] : [0]);

    (async () => {
      await api.getAllRoles().then((res) => {
        setRoleList([...res.data.result]);
      });

      await api
        .getWorkplaceMembers(getVisibleUsers(perms))
        .then((res) => {
          console.log("Get all members:", res);
          setMemberList([...res.data.result]);
        });

    })();
  }, []);

  const renderTab = () => {
    switch (tab[0]) {
      case 0: //role
        if (perms.testPermission([8, 10, 11, 12, 13], ["ViewRoles"]))
          return (
            <RolesTab
              list={roleList}
              mousePosition={mousePosition}
              setMousePosition={setMousePosition}
              perms={perms}
              setRoleList={setRoleList}
            />
          );
      case 1: //members
        if (perms.testPermission([8, 10, 11, 12, 13], ["ViewUsers"]))
          return (
            <MembersTab
              list={memberList}
              setMemberList={setMemberList}
              mousePosition={mousePosition}
              perms={perms}
              setMousePosition={setMousePosition}
              roleList={roleList}
              onCreateUser={() => {
                navigate("/system/createuser?tab=1");
              }}
            />
          );
      default:
        return <></>;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* header */}
      <TabsHeader
        currentTabs={[tab]}
        onTabChange={(newTab) => setTab(newTab)}
        options={[{ option: "Roles" }, { option: "Users" }]}
        // rootStyle={{ width: "98%" }}
      />

      {/* content */}
      <div
        style={{
          display: "flex",
          // width: "98%",
          // height: 700,
          height: "calc(100% - 44px)",
        }}
      >
        {renderTab()}
      </div>
    </div>
  );
}
