import React, { useContext, useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";

import aware from "assets/aware.png";
import awarecam from "assets/awarecam.png";
import aware_alarm_camera_cell from "assets/awareicons/aware_alarm_camera_cell.png";
import aware_alarm_camera_sat from "assets/awareicons/aware_alarm_camera_sat.png";
import aware_alarm_nocamera from "assets/awareicons/aware_alarm_nocamera.png";
import aware_camera_cell from "assets/awareicons/aware_camera_cell.png";
import aware_camera_sat from "assets/awareicons/aware_camera_sat.png";
import aware_offline_camera_cell from "assets/awareicons/aware_offline_camera_cell.png";
import aware_offline_camera_sat from "assets/awareicons/aware_offline_camera_sat.png";
import aware_offline_nocamera from "assets/awareicons/aware_offline_nocamera.png";

import LocationSetter from "components/AlertCreator/components/LocationSetter";
import Cookies from "js-cookie";
import WidgetMap from "components/WidgetMap";
import FullScreenMap from "components/FullScreenMap";

import { ColorContext, LayoutSizeContext } from "App";
import { borderRadius } from "@mui/system";
import { extractDateTimeFromUrl } from "utils/dateUtils";

const iconSizes = [25, 41];

const icons = {
  aware: L.icon({ iconUrl: aware, iconSize: iconSizes }),
  awareCam: L.icon({ iconUrl: awarecam, iconSize: iconSizes }),
  awareAlarmCameraCell: L.icon({
    iconUrl: aware_alarm_camera_cell,
    iconSize: iconSizes,
  }),
  awareAlarmCameraSat: L.icon({
    iconUrl: aware_alarm_camera_sat,
    iconSize: iconSizes,
  }),
  awareAlarmNoCamera: L.icon({
    iconUrl: aware_alarm_nocamera,
    iconSize: iconSizes,
  }),
  awareCameraCell: L.icon({ iconUrl: aware_camera_cell, iconSize: iconSizes }),
  awareCameraSat: L.icon({ iconUrl: aware_camera_sat, iconSize: iconSizes }),
  awareOfflineCameraCell: L.icon({
    iconUrl: aware_offline_camera_cell,
    iconSize: iconSizes,
  }),
  awareOfflineCameraSat: L.icon({
    iconUrl: aware_offline_camera_sat,
    iconSize: iconSizes,
  }),
  awareOfflineNoCamera: L.icon({
    iconUrl: aware_offline_nocamera,
    iconSize: iconSizes,
  }),
};

function getIcon(device) {
  const currentTime = Date.now();
  const timeDifference = (currentTime - device.rawData.ts) / (1000 * 60); // convert to minutes

  const isOffline = timeDifference > 70;

  if (isOffline) {
    return device.rawData.gatewayType
      ? icons.awareOfflineCameraCell
      : device.rawData.pict
      ? icons.awareOfflineCameraSat
      : icons.awareOfflineNoCamera;
  }

  if (device.rawData.ffi1 || device.rawData.drInd || device.rawData.depthInd) {
    if (device.rawData.pict) {
      return device.rawData.gatewayType
        ? icons.awareAlarmCameraCell
        : icons.awareAlarmCameraSat;
    }
    return icons.awareAlarmNoCamera;
  }

  if (device.rawData.pict) {
    return device.rawData.gatewayType
      ? icons.awareCameraCell
      : icons.awareCameraSat;
  }

  return icons.aware;
}

const useCloseAllPopups = () => {
  const map = useMap();

  const closeAllPopups = () => {
    map.eachLayer((layer) => {
      if (layer instanceof L.Popup) {
        layer.remove();
      }
    });
  };

  return closeAllPopups;
};

const ClosePopups = ({ closePopups }) => {
  const closeAllPopups = useCloseAllPopups();

  useEffect(() => {
    closeAllPopups();
  }, [closePopups]);

  return null;
};


export default function DevicesMap({
  selectedDevice,
  nodeList,
  setSelectedDevice,
  setPageIndex,
  simpleDeviceData,
  setShowCurrentDevice,
  longMap,
  tallMap,
  widget,
  sidebarOpen,
  preview = false,
}) {
  const layout = useContext(LayoutSizeContext);

  const theme = useContext(ColorContext);
  const [userPosition, setUserPosition] = useState(null);
  const [closePopups, setClosePopups] = useState(false);

  // console.log("Node Data:", nodeData, "arrayOfDevices:", arrayOfDevices);

  useEffect(() => {
    if (Cookies.get("latitude") && Cookies.get("longitude")) {
      setUserPosition([Cookies.get("latitude"), Cookies.get("longitude")]);
      return;
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserPosition([position.coords.latitude, position.coords.longitude]);
      });
    }
  }, []);

  const [mapCenter, setMapCenter] = useState([
    Cookies.get("latitude"),
    Cookies.get("longitude"),
  ]);

  useEffect(() => {
    console.log("Selected Device:", selectedDevice, nodeList[selectedDevice]);
    if (selectedDevice !== null && nodeList[selectedDevice] && nodeList[selectedDevice].lat && nodeList[selectedDevice].lon) {
      
      setMapCenter([nodeList[selectedDevice].lat, nodeList[selectedDevice].lon]);
    }
  }, [selectedDevice]);

  if (!widget) {
    return (
      <div
        style={{
          height: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 1000,
            marginLeft: 60,
            marginTop: 12,
          }}
        >
          <div
            style={{
              background: theme.primaryHighlight,
              borderRadius: 100,
              padding: 10,
              width: 300,
            }}
          >
            <select
              style={{
                background: theme.primaryHighlight,
                color: theme.fontColor,
                padding: 0,
                borderRadius: 5,
                border: "none",
                cursor: "pointer",
                width: "100%",
              }}
              onChange={(e) => {
                // let dIndex = 0;
                // let deviceObj = {};
                // nodeList.forEach((device, index) => {
                //   if (device.nodeId.toString() === e.target.value) {
                //     dIndex = index;
                //     deviceObj = device;
                //   }
                // });

                setClosePopups(!closePopups);
                // setMapCenter([nodeList[selectedDevice].lat, nodeList[selectedDevice].lon]);
                setSelectedDevice(e.target.value);
                setPageIndex(simpleDeviceData[e.target.value].data.length);
                setShowCurrentDevice(true);
                setClosePopups(!closePopups);
              }}
              value={selectedDevice}
            >
              <option value="" disabled>
                Select Device
              </option>
              {nodeList.map((device, i) => (
                <option key={device.nodeId} value={i}>
                  {device.rawData.node_alias}
                </option>
              ))}
            </select>
          </div>
        </div>
        {userPosition && nodeList && (
          <div style={{ height: "100%" }}>
            <FullScreenMap
              key={"fullScreenMap"}
              mapCenter={userPosition}
              zoom={13}
              style={{ borderRadius: 10 }}
              // satelliteToggleStyle={{ bottom: "25px", left: "30px" }}
            >
              <LocationSetter center={mapCenter} zoom={16} />
              <ClosePopups closePopups={closePopups} />

              <Marker position={userPosition}>
                <Popup
                >Your location</Popup>
              </Marker>
              {nodeList.map((device, index) => (
                <Marker
                  key={device.nodeId}
                  position={[device.lat, device.lon]}
                  icon={getIcon(device)}
                >
                  <Popup
                  >
                    <div
                      style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {/* <h2>Node ID: {device.nodeId}</h2> */}
                      <h2>{device.rawData.node_alias}</h2>
                      <div>
                        {new Date(device.rawData.ts).toLocaleDateString()}, 
                        {new Date(device.rawData.ts).toLocaleTimeString()}
                      </div>
                      <div style={{
                        marginBottom: 10
                      }}>Status: {device.status}</div>
                      {/* <p>Type: {device.type}</p> */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: 10,
                          justifyContent: "space-between",
                          gap: 20,
                          textWrap: "none",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "left",
                            textWrap: "none",
                            gap: 5,
                          }}
                        >
                        <div style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                          <div style={{width: 25, height: 10, background:'#FFFF00', marginRight: 5, marginTop: 2}}></div>
                          Sensor Depth:{" "}
                          {(device.rawData.depth1 + device.rawData.water_level_adj) +
                            " in/" +
                            ((device.rawData.depth1 + device.rawData.water_level_adj) / 12).toFixed(3) +
                            " ft"}
                        </div>
                          <div style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                            <div style={{width: 25, height: 10, background:'#0075ff', marginRight: 5, marginTop: 2}}></div>
                            Depth:{" "}
                            {device.rawData.depth1 +
                              " in/" +
                              (device.rawData.depth1 / 12).toFixed(3) +
                              " ft"}
                          </div>
                          <div style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                            <div style={{width: 25, height: 10, background:'#62f671', marginRight: 5, marginTop: 2}}></div>
                            Temperature:{" "}
                            {device.rawData.h2oTemp +
                              "°C/" +
                              ((device.rawData.h2oTemp * 9) / 5 + 32).toFixed(
                                2
                              ) +
                              " °F"}
                          </div>
                          <div style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                            <div style={{width: 25, height: 10, background:'#7f00ff', marginRight: 5, marginTop: 2}}></div>
                            Pressure:
                            {device.rawData.baro +
                              " mBar/" +
                              (device.rawData.baro * 0.02952998).toFixed(3) +
                              " inHg"}
                          </div>
                          <div style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                            <div style={{width: 25, height: 10, background:'#afb943', marginRight: 5, marginTop: 2}}></div>
                            Battery: {device.rawData.battery}V </div>
                          <div style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                            <div style={{width: 25, height: 10, background:'red', marginRight: 5, marginTop: 2}}></div>
                            Alert Indicated:{" "}
                            {device.rawData.ffi1 ||
                            device.rawData.drInd ||
                            device.rawData.depthInd
                              ? "True"
                              : "False"}
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {device.rawData.img_link_url && (
                            <img
                              src={`${device.rawData.img_link_url}`}
                              alt="Device"
                              style={{ width: "200px", height: "150px" }}
                            />
                          )}
                          <div>
                            Image Timestamp:{" \n"}
                            {
                              extractDateTimeFromUrl(device.rawData.img_link_url)
                            }
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "5px 10px",
                          backgroundColor: theme.primary,
                          color: theme.fontColor,
                          borderRadius: 5,
                          textAlign: "center",
                          cursor: "pointer",
                          width: 'fit-content',
                          margin: 'auto'
                        }}
                        onClick={() => {
                          setMapCenter([device.lat, device.lon]);
                          setSelectedDevice(index);
                          setPageIndex(simpleDeviceData[index].data.length);
                          setShowCurrentDevice(true);
                        }}
                      >
                        View device
                      </div>
                    </div>
                  </Popup>
                </Marker>
              ))}
            </FullScreenMap>
          </div>
        )}
      </div>
    );
  }

  if (widget) {
    return (
      <div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              zIndex: 1000,
              background: theme.primaryHighlight,
              position: "absolute",
              top: 80,
              padding: 10,
              borderRadius: 100,
              width: layout.widgetWidth - 160,
            }}
          >
            <select
              style={{
                background: theme.primaryHighlight,
                color: theme.fontColor,
                padding: 0,
                borderRadius: 5,
                border: "none",
                cursor: "pointer",
                width: "100%",
              }}
              onChange={(e) => {
                let dIndex = 0;
                let deviceObj = {};
                nodeList.forEach((device, index) => {
                  if (device.nodeId.toString() === e.target.value) {
                    dIndex = index;
                    deviceObj = device;
                  }
                });
                setClosePopups(!closePopups);
                setMapCenter([deviceObj.lat, deviceObj.lon]);
                setSelectedDevice(dIndex);
                setShowCurrentDevice(true);
                setClosePopups(!closePopups);
              }}
              value={selectedDevice.nodeId}
            >
              <option value="" disabled>
                Select Device
              </option>
              {nodeList.map((device) => (
                <option key={device.nodeId} value={device.nodeId}>
                  {device.rawData.node_alias}
                </option>
              ))}
            </select>
          </div>
        </div>

        {userPosition && nodeList && (
          <div style={{ position: "relative" }}>
            <WidgetMap
              sidebarOpen={sidebarOpen}
              key={"widgetMap"}
              mapCenter={userPosition}
              zoom={13}
              longMap={longMap}
              tallMap={tallMap}
              preview={preview}
            >
              <LocationSetter center={mapCenter} zoom={16} />
              <ClosePopups closePopups={closePopups} />

              <Marker position={userPosition}>
                <Popup>Your location</Popup>
              </Marker>
              {nodeList.map((device) => (
                <Marker
                  key={device.nodeId}
                  position={[device.lat, device.lon]}
                  icon={getIcon(device)}
                >
                  <Popup>
                    <div>
                      <h2>Node ID: {device.nodeId}</h2>
                      <p>Status: {device.status}</p>
                      <p>Type: {device.type}</p>
                      {device.rawData.img_link_url && (
                        <img
                          src={`${device.rawData.img_link_url}`}
                          alt="Device"
                          style={{ width: "100px", height: "100px" }}
                        />
                      )}
                    </div>
                  </Popup>
                </Marker>
              ))}
            </WidgetMap>
          </div>
        )}
      </div>
    );
  }
}
