import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as api from "../../../apis/FusionAPI";
import ConfirmPopup from "components/UI/ConfirmPopup";
import ContentRow from "./ContentRow";
import PopupMenu from "./PopupMenu";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ColorContext, LayoutSizeContext } from "App";
import { Client } from "@microsoft/microsoft-graph-client";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import Popup from "reactjs-popup";
import { FaX } from "react-icons/fa6";
import { group, link } from "d3";
import { RoleDropdown } from "../RoleDropdown";
import microsoftlogo from "assets/microsoftlogo.png";
import Cookies from "js-cookie";
import { FaChevronDown, FaChevronUp, FaLock, FaEye } from "react-icons/fa";

export default function RolesTab({
  list,
  mousePosition,
  setMousePosition,
  perms,
  setRoleList,
}) {
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();

  const layout = useContext(LayoutSizeContext);

  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState(-1);
  const [showConfirmDeleteRole, setShowConfirmDeleteRole] = useState(false);
  const [showConfirmLinkRoles, setShowConfirmLinkRoles] = useState(false);
  const [showCantRemoveRole, setShowCantRemoveRole] = useState(false);
  const [showImportRoles, setShowImportRoles] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [links, setLinks] = useState({});
  const [isMsalAuthenticated, setIsMsalAuthenticated] = useState(false);
  const [hierarchy, setHierarchy] = useState({});
  const [originalHierarchy, setOriginalHierarchy] = useState({});
  const [linkMulti, setLinkMulti] = useState({});

  const [yourHighestRoleHierarchyId, setYourHighestRoleHierarchyId] =
    useState(null);
  const [msGroups, setMsGroups] = useState([]);
  const [hierarchyChanged, setHierarchyChanged] = useState(false);

  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    setRoleList(
      list.sort((a, b) => {
        if (parseInt(a.role_Id) === -2) return 1; // Move 'a' to the bottom
        if (parseInt(b.role_Id) === -2) return -1; // Move 'b' to the bottom

        if (parseInt(a.role_Id) === -1) return -1; // Move 'a' to the bottom
        if (parseInt(b.role_Id) === -1) return 1; // Move 'b' to the bottom
        if (parseInt(a.role_Id) < 0) return -1; // Move 'a' to the bottom
        if (parseInt(b.role_Id) < 0) return 1; // Move 'b' to the bottom

        return hierarchy[a.role_Id] - hierarchy[b.role_Id];
      })
    );
  }, [list]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const msalauth = await api.tryMsAuthSilent(instance);
        console.log("MSAL Auth:", msalauth);
        setIsMsalAuthenticated(msalauth !== null);
        const res = await api.getAllRoles(instance);
        console.log("Get all roles:", res);
        setRoleList([...res.data.result]);
        const rolesToHierarch = [...res.data.result].filter((item) => {
          return parseInt(item.role_Id) >= 0;
        });
        let hierarchy = {};
        // console.log("rolesToHierarch", rolesToHierarch);
        rolesToHierarch.forEach((role) => {
          hierarchy[role.role_Id] = role.hierarchy_level;
        });
        let max = 999;

        let l = [...res.data.result];
        console.log("list", l);
        const myRoles = l
          .filter((item) => {
            if (item.users?.includes(Cookies.get("username"))) {
              return item.role_Id;
            }
          })
          .map((item) => {
            return item.role_Id;
          });

        console.log("My Roles:", myRoles);
        rolesToHierarch.forEach((role) => {
          if (myRoles.includes(role.role_Id)) {
            console.log("Role:", role);
            if (role.hierarchy_level <= max) {
              max = role.hierarchy_level;
            }
          } else {
            console.log("Role not in my scope:", role);
          }
        });
        setYourHighestRoleHierarchyId(max);
        console.log("Your Highest Role Hierarchy Id:", max);

        setHierarchy(hierarchy);
        setOriginalHierarchy(hierarchy);
        // console.log("Loaded hierarchy:", hierarchy);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);

  useEffect(() => {
    if (JSON.stringify(hierarchy) === JSON.stringify(originalHierarchy)) {
      setHierarchyChanged(false);
    }
    console.log("Hierarchy changed:", hierarchy, originalHierarchy);
  }, [hierarchy]);

  const theme = useContext(ColorContext);

  const handleDeleteRole = async (roleId) => {
    const res = await api.deleteRole(roleId);
    setShowConfirmDeleteRole(false);
    console.log("Delete role:", res);
    //remove roleid entry from hierarchy
    const newHierarchy = { ...hierarchy };
    delete newHierarchy[roleId];
    setHierarchy(newHierarchy);

    await api.getAllRoles(instance).then((res) => {
      console.log("Get all roles:", res);
      setRoleList([...res.data.result]);
      setShowConfirmDeleteRole(false);
    });
  };

  const onCreateRole = async () => {
    navigate("/system/createrole");
  };

  const getMsInfo = async () => {
    //MS Graph API
    const scopes = ["Group.Read.All"];
    const msRes = api.tryMsAuthSilent(instance, scopes);

    if (msRes) {
      const client = Client.init({
        authProvider: (done) => {
          done(null, msRes.accessToken);
        },
      });

      //move to backend @ BRENTON
      // const user = await client.api("/me").get();
      // console.log("User", user);
      // const groups = await client.api("/groups").get();
      // console.log("Groups", groups);
      // setMsGroups(groups.value);
      let groups = await api.ms_getSecurityGroups(instance);
      console.log("Groups:", groups);
      setMsGroups(groups.data.result);

      // get links
      const res = await api.ms_getRoleLinks(instance);
      const links = res.data.result;
      // console.log("Role Links:", links);
      // const links = {};
      const linkMultis = {};
      Object.keys(links).forEach((link) => {
        // console.log("link", link)
        // links[link] = link.role_id;
        linkMultis[link] = links[link];
      });
      // console.log("linkMultis:", linkMultis);
      setLinkMulti(linkMultis);
      // const getMyGroup = await client.api("/me/memberOf").get();
      // console.log("My Groups", getMyGroup);
      // const getListOfMembersForGroups = await client
      //   .api("/groups/13fd292a-06fa-4433-bf89-19effc73cb2d/members")
      //   .get();
      // console.log("Members of Group", getListOfMembersForGroups);
    }
  };

  const onImportRoles = async () => {
    setShowImportRoles(true);
    getMsInfo();
  };

  const isPrimaryRole = (role) => {
    return role.role_Id === -1;
  };

  useEffect(() => {
    // console.log("linkMulti", linkMulti);
  }, [linkMulti]);

  const sendImportRoles = async () => {
    const res = await api.ms_updateRoleLinks(instance, linkMulti);
    setShowImportRoles(false);
    setShowConfirmLinkRoles(false);
    //get roles
    const res2 = await api.getAllRoles(instance);
    setRoleList([...res2.data.result]);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <Popup
        open={showImportRoles}
        onClose={() => setShowImportRoles(false)}
        modal
        closeOnDocumentClick={false}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            background: theme.base,
            border: "solid white 1px",
            borderRadius: 10,
            color: theme.fontColor,
            padding: 10,
            width: "50vw",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: 20,
            }}
          >
            <div style={{ display: "flex" }}>
              <img
                src={microsoftlogo}
                style={{ height: 20, marginRight: 10 }}
              />
              <h3> Link Security Groups</h3>
            </div>
            <button onClick={() => setShowImportRoles(false)}>
              <FaX />
            </button>
          </div>
          <div
            style={{
              marginBottom: 10,
            }}
          >
            {/* Select a Microsoft Security Group to link to a Fusion Role: */}
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              alignContent: "center",
              alignItems: "center",
              gap: 10,
              marginBottom: 10,
            }}
          >
            <div>Microsoft Security Group:</div>
            <div>Fusion Roles:</div>
          </div>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              gap: 10,
            }}
          >
            <div
              className="customScrollbar-Y"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "400px",
                overflow: "auto",
              }}
            >
              {msGroups
                .sort((a, b) => {
                  return a.displayName.localeCompare(b.displayName);
                })
                .map((group, i) => {
                  return (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        background: i % 2 === 0 ? "" : "#2c373b",
                        padding: 10,
                        alignContent: "center",
                      }}
                      key={i}
                    >
                      {group.displayName}
                      <div
                        style={{
                          display: "flex",
                          alignContent: "center",
                          gap: 5,
                        }}
                      >
                        <Popup
                          trigger={
                            <div
                              style={{
                                width: "calc(100% - 20px)",
                                height: 31,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                paddingLeft: 10,
                                paddingRight: 10,
                                backgroundColor: theme.primary,
                                fontSize: 13,
                                borderRadius: 10,
                                gap: 20,
                                border: `1px solid grey`,
                                color: theme.fontColor, // Added to ensure text is visible against the background
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {(() => {
                                  if (!linkMulti[group.id]) return "";
                                  const rolesString = linkMulti[group.id]
                                    .map((role) => {
                                      return list.find(
                                        (r) => r.role_Id === role
                                      ).role_name;
                                    })
                                    .join(", ");
                                  return rolesString;
                                })()}
                              </div>
                            </div>
                          }
                          position="bottom center"
                          on="hover"
                          closeOnDocumentClick={false}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "left",
                              padding: 10,
                              backgroundColor: theme.primary,
                              backgroundColor: "#436C9A",
                              color: theme.fontColor,
                              borderRadius: 10,
                              cursor: "pointer",
                              width: "22.75vw",
                              marginLeft: 6,
                              // gap: 5
                            }}
                          >
                            {list &&
                              list
                                .filter((role) => {
                                  return role.role_Id >= 0;
                                })
                                .map((item, index) => {
                                  // console.log("Item:", item);
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        background: linkMulti[
                                          group.id
                                        ]?.includes(item.role_Id)
                                          ? theme.primary
                                          : "",
                                        padding: 5,
                                        // borderRadius: 5,
                                      }}
                                      onClick={() => {
                                        setLinkMulti({
                                          ...linkMulti,
                                          [group.id]: linkMulti[
                                            group.id
                                          ]?.includes(item.role_Id)
                                            ? linkMulti[group.id].filter(
                                                (r) => r !== item.role_Id
                                              )
                                            : [
                                                ...(linkMulti[group.id] || []),
                                                item.role_Id,
                                              ],
                                        });
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        checked={linkMulti[group.id]?.includes(
                                          item.role_Id
                                        )}
                                        readOnly
                                      />{" "}
                                      {item.role_name}
                                    </div>
                                  );
                                })}
                          </div>
                        </Popup>
                        <div
                          onClick={() => {
                            setLinkMulti({
                              ...linkMulti,
                              [group.id]: [],
                            });
                          }}
                          style={{
                            alignContent: "center",
                          }}
                        >
                          <FaX />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              onClick={() => {
                setShowConfirmLinkRoles(true);
              }}
              style={{
                backgroundColor: theme.primary,
                color: theme.fontColor,
                borderRadius: 5,
                width: 100,
                height: 50,
                marginTop: 10,
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Popup>
      {showConfirmLinkRoles && (
        <ConfirmPopup
          open={showConfirmLinkRoles}
          setOpen={setShowConfirmLinkRoles}
          style={{
            width: 500,
            whiteSpace: "pre-line",
          }}
          message={`Important Notice:\n

You are about to link a Microsoft Security Group to a Role within Overwatch Fusion+. This is a critical action that requires careful consideration.\n

Once linked, all users in the selected security group will be imported into Overwatch Fusion+ and assigned the corresponding role. This role determines their access to specific permissions, widgets, and dashboard layouts within the app.\n

Although this action can be reversed, we strongly recommend ensuring the security group is appropriate for the intended role and permissions before proceeding.\n

By continuing, you acknowledge the significance of this action and confirm your intent to proceed with the import and role assignment.`}
          options={["Cancel", "Yes"]}
          buttonStyles={[
            { backgroundColor: theme.background, color: theme.fontColor },
            { backgroundColor: theme.primaryHighlight, color: theme.fontColor },
          ]}
          callbacks={[
            () => setShowConfirmLinkRoles(false),
            () => sendImportRoles(),
          ]}
        />
      )}
      {showConfirmDeleteRole && (
        <ConfirmPopup
          open={showConfirmDeleteRole}
          setOpen={setShowConfirmDeleteRole}
          message={`Are you sure you want to remove this role?`}
          options={["Cancel", "Yes"]}
          buttonStyles={[
            { backgroundColor: theme.background, color: theme.fontColor },
            { backgroundColor: theme.primaryHighlight, color: theme.fontColor },
          ]}
          callbacks={[
            () => setShowConfirmDeleteRole(false),
            () => handleDeleteRole(itemToDelete.role_Id),
          ]}
        />
      )}
      {showCantRemoveRole && (
        <ConfirmPopup
          open={showCantRemoveRole}
          setOpen={setShowCantRemoveRole}
          message={`The ${itemToDelete.role_name} Role cannot be removed with ${
            itemToDelete.role_count
          } assigned User${
            itemToDelete.role_count > 1 ? "s" : ""
          }. Remove all users from this role and try again.`}
          options={["Ok"]}
          buttonStyles={[
            { backgroundColor: theme.background, color: theme.fontColor },
          ]}
          callbacks={[() => setShowCantRemoveRole(false)]}
        />
      )}
      <div
        style={{
          display: "flex",
          width: "100%",
          // background: 'red',
          justifyContent: "space-between",
        }}
      >
        <div>
          {perms.accessLevel >= 12 && hierarchyChanged && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // width: 176,
                  padding: 10,
                  // height: 65,
                  backgroundColor: theme.primary,
                  borderRadius: 5,
                  marginRight: 20,
                }}
                onClick={async () => {
                  console.log("Submitting hierarchy:", hierarchy);
                  const res = await api.updateRoleHierarchy(hierarchy);
                  console.log("Update Role Hierarchy:", res);
                  setHierarchyChanged(false);
                }}
              >
                <div>Save</div>
              </button>
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // width: 176,
                  padding: 10,
                  // height: 65,
                  // backgroundColor: theme.primary,
                  border: "1px solid white",
                  borderRadius: 5,
                  marginRight: 20,
                }}
                onClick={async () => {
                  setHierarchy(originalHierarchy);
                  setHierarchyChanged(false);
                }}
              >
                <div>Discard</div>
              </button>
              <div>
                Careful! You have unsaved changes to the Organization's Role
                Hierarchy, this controls the highest-ranked role of all Client
                Users.
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignSelf: "flex-end",
          }}
        >
          <div
            style={{
              height: 39,
              backgroundColor: theme.primary,
              borderRadius: 10,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <input
              placeholder="Search..."
              style={{
                width: "100%",
                height: 39,
              }}
              onChange={(value) => setSearch(value.target.value)}
              value={search}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr .5fr 2fr .25fr",
          padding: 20,
          textAlign: "left",
          borderBottom: "1px solid gray",
          textAlign: "left",
          fontSize: 20,
        }}
      >
        <div style={{ marginLeft: 50 }}>Roles</div>
        <div>Users</div>
        <div>Created By</div>
        <div />
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
        }}
      >
        {list.length === 0 && <WidgetLoadingAnimation />}
        {list &&
          list
            .filter((item) => {
              return true;
              return item.role_Id >= 0;
            })
            .sort((a, b) => {
              if (parseInt(a.role_Id) === -2) return 1; // Move 'a' to the bottom
              if (parseInt(b.role_Id) === -2) return -1; // Move 'b' to the bottom

              if (parseInt(a.role_Id) === -1) return -1; // Move 'a' to the bottom
              if (parseInt(b.role_Id) === -1) return 1; // Move 'b' to the bottom
              if (parseInt(a.role_Id) < 0) return -1; // Move 'a' to the bottom
              if (parseInt(b.role_Id) < 0) return 1; // Move 'b' to the bottom

              return hierarchy[a.role_Id] - hierarchy[b.role_Id];
            })
            .map((item, index) => {
              return (
                <div
                  key={index}
                  style={
                    {
                      // background:'yellow',
                      // width: "100%"
                    }
                  }
                >
                  {item.role_name
                    .toLowerCase()
                    .replace(/ /g, "")
                    .includes(search.toLowerCase().replace(/ /g, "")) && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr .5fr 2fr .25fr",
                        padding: 20,
                        gap: 10,
                        textAlign: "left",
                        borderBottom: "1px solid gray",
                        height: 50,
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 5,
                          justifyItems: "flex-start",
                          // background:'red',
                          alignItems: "center",
                        }}
                      >
                        {(item.role_Id >= 0 && perms.accessLevel >= 12) ||
                        perms.accessLevel == 8 ? (
                          <div
                            style={{
                              width: 50,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: 17,
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {hierarchy[item.role_Id] > 0 && (
                                <div
                                  onClick={() => {
                                    console.log(
                                      "Move up",
                                      item.role_name,
                                      list[index].role_name
                                    );
                                    const useList = list.sort((a, b) => {
                                      if (parseInt(a.role_Id) === -2) return 1; // Move 'a' to the bottom
                                      if (parseInt(b.role_Id) === -2) return -1; // Move 'b' to the bottom

                                      if (parseInt(a.role_Id) === -1) return -1; // Move 'a' to the bottom
                                      if (parseInt(b.role_Id) === -1) return 1; // Move 'b' to the bottom
                                      if (parseInt(a.role_Id) < 0) return -1; // Move 'a' to the bottom
                                      if (parseInt(b.role_Id) < 0) return 1; // Move 'b' to the bottom

                                      return (
                                        hierarchy[a.role_Id] -
                                        hierarchy[b.role_Id]
                                      );
                                    });
                                    console.log("Use List", useList);
                                    setHierarchyChanged(true);
                                    //move this up one and the one above down one
                                    const newHierarchy = { ...hierarchy };
                                    const newRole = { ...useList[index] };
                                    const oldRole = { ...useList[index - 1] };
                                    newHierarchy[newRole.role_Id] -= 1;
                                    newHierarchy[oldRole.role_Id] += 1;
                                    setHierarchy(newHierarchy);
                                  }}
                                >
                                  <FaChevronUp />
                                </div>
                              )}
                              <div>{hierarchy[item.role_Id] + 1}</div>
                              {hierarchy[item.role_Id] <
                                Object.keys(hierarchy).length - 1 && (
                                <div
                                  onClick={() => {
                                    console.log("Move down", item.role_name);
                                    const useList = list.sort((a, b) => {
                                      if (parseInt(a.role_Id) === -2) return 1; // Move 'a' to the bottom
                                      if (parseInt(b.role_Id) === -2) return -1; // Move 'b' to the bottom

                                      if (parseInt(a.role_Id) === -1) return -1; // Move 'a' to the bottom
                                      if (parseInt(b.role_Id) === -1) return 1; // Move 'b' to the bottom
                                      if (parseInt(a.role_Id) < 0) return -1; // Move 'a' to the bottom
                                      if (parseInt(b.role_Id) < 0) return 1; // Move 'b' to the bottom

                                      return (
                                        hierarchy[a.role_Id] -
                                        hierarchy[b.role_Id]
                                      );
                                    });
                                    console.log("Use List", useList);
                                    setHierarchyChanged(true);
                                    //move this down one and the one below up one
                                    const newHierarchy = { ...hierarchy };
                                    const newRole = { ...useList[index] };
                                    const oldRole = { ...useList[index + 1] };
                                    newHierarchy[newRole.role_Id] += 1;
                                    newHierarchy[oldRole.role_Id] -= 1;
                                    setHierarchy(newHierarchy);
                                  }}
                                >
                                  <FaChevronDown />
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 50,
                              opacity: 0.5,
                              display: "flex",
                            }}
                          >
                            {perms.accessLevel === 10 &&
                            item.role_Id !== -2 &&
                            perms.accessLevel === 10 &&
                            item.hierarchy_level >
                              yourHighestRoleHierarchyId ? (
                              <div></div>
                            ) : (
                              <FaLock />
                            )}
                          </div>
                        )}
                        {item.role_name}
                      </div>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <Popup
                          trigger={<div>{item.role_count}</div>}
                          position="top center"
                          on="hover"
                          closeOnDocumentClick
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 5,
                              fontSize: 16,
                              fontWeight: "normal",
                              color: theme.fontColor,
                              backgroundColor: theme.primary,
                              borderRadius: 5,
                            }}
                          >
                            {(item.users || ["No Users"]).map((user, i) => {
                              return (
                                <div
                                  key={i}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    padding: 5,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    backgroundColor:
                                      i % 2 === 0 ? "" : "#2c373b",
                                  }}
                                >
                                  <div>{user}</div>
                                </div>
                              );
                            })}
                          </div>
                        </Popup>
                      </div>
                      <div>{item.created_by_email}</div>
                      {(perms.testPermission(
                        [8, 10, 11, 12, 13],
                        ["EditRole"]
                      ) ||
                        perms.testPermission(
                          [8, 10, 11, 12, 13],
                          ["EditRole"]
                        ) ||
                        perms.testPermission(
                          [8, 10, 11, 12, 13],
                          ["DeleteRole"]
                        )) &&
                      //or user is 12 or 13
                      item.role_Id >= 0 &&
                      (perms.accessLevel === 12 ||
                        perms.accessLevel === 13 ||
                        item.hierarchy_level > yourHighestRoleHierarchyId) ? (
                        <Popup
                          position="top right"
                          trigger={
                            <button
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                // width: 1266 / 4,
                                // height: 71,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: "#2a3e52",
                                  borderRadius: 100,
                                  width: 39,
                                  height: 39,
                                }}
                              >
                                ...
                              </div>
                            </button>
                          }
                        >
                          <div>
                            {perms.testPermission(
                              [8, 10, 11, 12, 13],
                              ["EditRole"]
                            ) && (
                              <button
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: 200,
                                  height: 50,
                                  borderRadius: 15,
                                  backgroundColor: theme.primary,
                                  border: "solid black 1px",
                                  color: theme.fontColor,
                                }}
                                onClick={() =>
                                  navigate(
                                    `/system/editrole?roleid=${item.role_Id}`
                                  )
                                }
                              >
                                EDIT
                              </button>
                            )}
                            {perms.testPermission(
                              [8, 10, 11, 12, 13],
                              ["DeleteRole"]
                            ) && (
                              <button
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: 200,
                                  height: 50,
                                  borderRadius: 15,
                                  backgroundColor: "red",
                                  border: "solid black 1px",
                                  color: theme.fontColor,
                                }}
                                onClick={() => {
                                  item?.role_count > 0
                                    ? setShowCantRemoveRole(true)
                                    : setShowConfirmDeleteRole(true);
                                  setItemToDelete(item);
                                }}
                              >
                                REMOVE
                              </button>
                            )}
                          </div>
                        </Popup>
                      ) : (
                        <Popup
                          trigger={
                            <button
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                // width: 1266 / 4,
                                // height: 71,
                              }}
                              onClick={() => {
                                // if (item.role_Id <= 0) return;
                                navigate(
                                  `/system/viewrole?roleid=${item.role_Id}`
                                );
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: "#2a3e52",
                                  borderRadius: 100,
                                  width: 39,
                                  height: 39,
                                }}
                              >
                                <FaEye />
                              </div>
                            </button>
                          }
                          position="top center"
                          on="hover"
                          closeOnDocumentClick
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 5,
                              fontSize: 16,
                              fontWeight: "normal",
                              color: theme.fontColor,
                              backgroundColor: theme.primary,
                              borderRadius: 5,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate(
                                `/system/viewrole?roleid=${item.role_Id}`
                              );
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                padding: 5,
                                paddingLeft: 10,
                                paddingRight: 10,
                                backgroundColor: "#2c373b",
                              }}
                            >
                              <div>View Role</div>
                            </div>
                          </div>
                        </Popup>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {perms.testPermission([8, 11, 12, 13]) && isMsalAuthenticated && (
          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // width: 176,
              padding: 5,
              height: 65,
              backgroundColor: theme.primary,
              borderRadius: 5,
            }}
            onClick={onImportRoles}
          >
            <img src={microsoftlogo} style={{ height: 20, marginRight: 10 }} />
            <div>Link Security Groups</div>
          </button>
        )}
        {perms.testPermission([8, 10, 11, 12, 13], ["CreateRole"]) && (
          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 176,
              height: 65,
              backgroundColor: theme.primary,
              borderRadius: 5,
            }}
            onClick={onCreateRole}
          >
            <div>Create Role</div>
          </button>
        )}
      </div>
      {/* <PopupMenu
        contents={
          <div>
            {perms.testPermission([8, 10, 11, 12, 13], ["EditRole"]) && (
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  height: 50,
                  borderRadius: 15,
                  backgroundColor: theme.primary,
                  border: "solid black 1px",
                  color: theme.fontColor,
                }}
                onClick={() =>
                  navigate(`/system/editrole?roleid=${list[selected].role_Id}`)
                }
              >
                EDIT
              </button>
            )}
            {perms.testPermission([8, 10, 11, 12, 13], ["DeleteRole"]) && (
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  height: 50,
                  borderRadius: 15,
                  backgroundColor: "red",
                  border: "solid black 1px",
                  color: theme.fontColor,
                }}
                onClick={() => {
                  list[selected]?.role_count > 0
                    ? setShowCantRemoveRole(true)
                    : setShowConfirmDeleteRole(true);
                }}
              >
                REMOVE
              </button>
            )}
          </div>
        }
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        mousePosition={mousePosition}
      /> */}
    </div>
  );
}
