import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IconContext } from "react-icons";
import * as api from "../../apis/FusionAPI";
import GetWidgetData from "data/widgetData";
import "../Dashboard.css";
import { GetPermListForRole } from "data/permData";
import TabsHeader from "components/TabsHeader";
import DetailsTab from "./Components/DetailsTab";
import PermissionsTab from "./Components/PermissionsTab";
import DashboardEditTab from "./Components/DashboardEditTab";
import DashboardViewTab from "./Components/DashboardViewTab";
import { usePerms } from "hooks/usePerms";
import { ColorContext } from "App";
import { getVisibleUsers } from "utils/roleUtils";

export default function CreateRole() {
  let permList = GetPermListForRole();
  let widgetData = GetWidgetData();

  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [tab, setTab] = useState([0]);
  const [formData, setFormData] = useState({
    roleName: "",
    members: [],
    initialMembers: [],
    permissions: permList.map(() => false),
    layout: [],
  });
  const [checks, setChecks] = useState({ roleName: true });
  const [result, setResult] = useState("");
  const [membersList, setMembersList] = useState([]);
  const perms = usePerms();

  useEffect(() => {
    const initTab = [params.get("tab") ? parseInt(params.get("tab")) : 0];
    setTab([...initTab]);

    (async () => {
      if (!(await perms.validatePermissions(["CreateRole"]))) {
        navigate("/404");
        return;
      }
      const globalWidgetPermList = (await api.getGlobalWidgetPermList()).data
        .result;

      permList = permList.filter((filter, index) => {
        if (filter.linked > 0) {
          return globalWidgetPermList.permissions.includes(
            permList[index + 1].name
              .replace(/\s/g, "")
              .replace(/\//g, "-")
              .replace(/:/g, "_")
          );
        } else {
          return globalWidgetPermList.permissions.includes(
            filter.name
              .replace(/\s/g, "")
              .replace(/\//g, "-")
              .replace(/:/g, "_")
          );
        }
      });

      let newWidgetList = {};
      Object.keys(widgetData).forEach((key) => {
        if (globalWidgetPermList.widgetList.includes(parseInt(key))) {
          newWidgetList = { ...newWidgetList, [key]: widgetData[key] };
        }
      });

      widgetData = newWidgetList;

      setFormData({
        ...formData,
        permissions: permList.map(() => false),
      });

      api.getWorkplaceMembers(getVisibleUsers(perms)).then((res) => {
        const temp = [...res.data.result];
        temp.map((item) => (item = { ...item, selected: false }));
        setMembersList([...temp]);
      });
    })();
  }, []);

  const renderTab = () => {
    switch (tab[0]) {
      case 0:
        return (
          <DetailsTab
            formData={formData}
            setFormData={setFormData}
            checks={checks}
            membersList={membersList}
            setMembersList={setMembersList}
            widgetData={widgetData}
            permList={permList}
          />
        );
      case 1:
        return (
          <PermissionsTab
            formData={formData}
            setFormData={setFormData}
            checks={checks}
            widgetData={widgetData}
            permList={permList}
          />
        );
      case 2:
        return (
          <DashboardEditTab
            formData={formData}
            setFormData={setFormData}
            checks={checks}
            widgetData={widgetData}
            permList={permList}
            onNext={() => onNext()}
          />
        );
      case 3:
        return (
          <DashboardViewTab
            formData={formData}
            setFormData={setFormData}
            checks={checks}
            widgetData={widgetData}
            permList={permList}
          />
        );
      default:
        return <></>;
    }
  };

  const onBack = () => {
    if (tab[0] === 0) navigate("/system/rolemanager");
    else {
      setTab([tab[0] - 1]);
    }
  };

  const onNext = () => {
    if (tab[0] === 3) {
      setChecks({ roleName: formData.roleName !== "" });

      if (formData.roleName !== "") {
        onSubmit();
      }
    } else {
      setTab([tab[0] + 1]);
    }
  };

  const onSubmit = () => {
    let permissions = {};

    formData.permissions.map((perm, index) => {
      const permName = permList[index].name
        .replace(/\s/g, "")
        .replace(/\//g, "-")
        .replace(/:/g, "_");
      permissions = { ...permissions, [permName]: perm };
    });

    const members = [];
    membersList.map((member) => {
      if (member.selected) members.push(member.userId);
    });

    api
      .createRole(
        formData.roleName,
        members,
        JSON.stringify(permissions),
        JSON.stringify(formData.layout)
      )
      .then((res) => {
        setResult({ type: "success", message: "Role created successfully" });
        navigate("/system/rolemanager?tab=0");
      })
      .catch((e) => {
        setResult({
          type: "failed",
          message: "Failed: " + e.response.data.message,
        });
      });
  };

  
  const theme = useContext(ColorContext);
  return (
    <div style={{ userSelect: "none", marginRight: 30 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <button
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: theme.primary,
            width: 115,
            height: 31,
            marginTop: 18,
            borderRadius: 5,
          }}
          onClick={onBack}
        >
          <div style={{ display: "flex", alignItems: "center", marginLeft: 5 }}>
            <IconContext.Provider value={{ color: theme.fontColor, size: 20 }}>
              <FaChevronLeft />
            </IconContext.Provider>
          </div>
          <div style={{ marginRight: 5, fontSize: 13, fontWeight: "bold" }}>
            {tab == 0 ? "Return" : "Back"}
          </div>
        </button>
        <button
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            backgroundColor: theme.primary,
            width: 115,
            height: 31,
            marginTop: 18,
            borderRadius: 5,
          }}
          onClick={onNext}
        >
          <div style={{ marginLeft: 5, fontSize: 13, fontWeight: "bold" }}>
            {tab == 3 ? "Submit" : "Next"}
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginRight: 5 }}
          >
            <IconContext.Provider value={{ color: theme.fontColor, size: 20 }}>
              <FaChevronRight />
            </IconContext.Provider>
          </div>
        </button>
      </div>
      <div style={{ marginTop: 15 }}>
        <TabsHeader
          currentTabs={tab}
          onTabChange={(newTab) => setTab(newTab)}
          options={[
            { option: "Role Details" },
            { option: "Permissions" },
            { option: "Dashboard Edit" },
            { option: "Dashboard View" },
          ]}
        />
      </div>
      <div
        style={{
          display: "flex",
          backgroundColor: theme.base,
          alignItems: "center",
          justifyContent: "center",
          // marginTop: 17,
          // marginBottom: 20,
          height: "100%",
        }}
      >
        {renderTab()}
      </div>
      {result && (
        <div
          style={{
            color: result.type === "success" ? "green" : "red",
            fontSize: 30,
            fontWeight: "bold",
          }}
        >
          {result.message}
        </div>
      )}
    </div>
  );
}
